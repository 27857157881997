// Helpers

$input-select-margin-right: 1.5;

%block-input-general {
  @include border-box-sizing;
  @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
}

%block-input-styles {
  @include u-border(1px, "base-dark");
  appearance: none;
  border-radius: 0;
  color: color("ink"); // standardize on firefox
  display: block;
  height: units(5);
  margin-top: units(1);
  max-width: units($theme-input-max-width);
  padding: units(1);
  width: 100%;

  &.usa-input--success {
    @include u-border($theme-input-state-border-width, "success");
  }
}

// Block input elements
.usa-fieldset,
.usa-hint {
  @extend %block-input-general;
}

.usa-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.usa-form-group--error {
  @include u-border-left(0.5, "error-dark");
  margin-top: units(4);
  padding-left: units(2);
  position: relative;

  @include at-media("desktop") {
    margin-left: units(-2.5);
  }
}

.usa-error-message {
  @include u-padding-y(0.5);
  color: color("error-dark");
  display: block;
  font-weight: font-weight("bold");
}

.usa-hint {
  color: color("base");
}

.usa-label {
  display: block;
  line-height: line-height($theme-form-font-family, 2);
  margin-top: units(3);
  max-width: units($theme-input-max-width);
}

.usa-label--error {
  font-weight: font-weight("bold");
  margin-top: 0;
}

.usa-label--required {
  color: color("error-dark");
}

.usa-legend {
  font-size: font-size($theme-form-font-family, "xl");
  font-weight: font-weight("bold");
}
